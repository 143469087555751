import React from 'react';

export default function PublisherSVG() {
    return (
        <svg width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_206_255)">
                <path d="M24.7822 41.6324C24.0892 41.3188 23.3922 41.0052 22.7031 40.676C21.9127 40.2966 21.4494 39.7274 21.2041 39.4215L21.1379 39.3363C20.6045 38.7826 20.3164 38.0314 20.3436 37.2415C20.3709 36.4594 20.7135 35.7314 21.3053 35.1893L21.3481 35.1429C21.6168 34.8525 22.1191 34.3104 22.9367 33.9851C28.1501 31.9213 33.492 29.8304 38.2227 27.9757C37.0468 25.8305 35.4777 23.91 33.457 22.2605C32.5537 21.5209 31.9191 21.3506 31.0313 22.3224C30.2955 23.1317 29.361 23.8093 28.411 24.3746C22.5123 27.8905 15.391 27.1045 10.4112 22.3418C9.49625 21.4629 8.84603 21.3893 8.00503 22.2605C6.90316 23.4105 5.66502 24.4598 4.70722 25.7183C1.7092 29.6678 0.599549 34.3104 0.116752 39.1233C-0.284281 43.1232 0.159581 43.9518 4.19327 44.5675C8.3827 45.2064 20.5655 45.5781 27.7958 45.5316C27.4882 44.5559 27.169 43.5646 26.8419 42.5811C26.1761 42.2597 25.4986 41.9538 24.7822 41.6324Z" fill="black" />
                <path d="M20.7797 23.2556C27.1846 23.2092 32.324 18.0516 32.3474 11.6588C32.3707 5.17308 27.2196 0 20.733 0C14.2347 0 9.07968 5.14985 9.09525 11.6317C9.11082 18.1019 14.3398 23.3021 20.7758 23.2556H20.7797Z" fill="black" />
                <path d="M47.9682 27.3098C45.8773 28.1191 43.7865 28.9361 41.6996 29.757C40.9481 30.0512 40.2006 30.3455 39.4491 30.6398C34.3058 32.6533 29.1624 34.6667 24.023 36.6996C23.7349 36.8119 23.5324 37.1294 23.2871 37.3501C23.5129 37.5863 23.6959 37.9115 23.9724 38.0432C25.5103 38.7789 27.0872 39.4332 28.6134 40.1922C28.9794 40.3742 29.3376 40.7923 29.4661 41.1795C29.9528 42.6045 30.4045 44.041 30.8561 45.4775C31.0975 46.2365 31.335 46.9954 31.5764 47.7543C31.8568 48.6294 32.1488 48.7223 32.873 48.1841C33.9632 47.3787 35.065 46.5888 36.1202 45.737C36.6964 45.2723 37.1481 45.222 37.7827 45.6673C39.7723 47.0612 41.8008 48.401 43.8216 49.7446C44.4835 50.186 44.8767 50.0582 45.0091 49.369C45.5347 46.6043 46.0448 43.8397 46.5587 41.0711C47.2011 37.6211 47.8358 34.1672 48.4743 30.7134C48.6534 29.7531 48.8286 28.7928 48.9999 27.8596C48.9922 27.2324 48.6378 27.0465 47.9682 27.3098ZM40.5393 34.4344C40.1889 34.8216 39.8307 35.205 39.4608 35.5728C37.6425 37.3811 35.7347 39.1041 33.9009 40.9008C33.5816 41.2144 33.3635 41.6907 33.2467 42.1321C33.0326 42.9646 32.9353 43.8242 32.7756 44.6683C32.7484 44.8154 32.6588 44.9548 32.5187 45.2878C32.1838 44.2424 31.9113 43.3711 31.6348 42.5038C31.3895 41.7294 31.1403 40.955 30.8834 40.1844C30.7588 39.8205 30.7705 39.5494 31.1598 39.3132C34.2318 37.4236 37.296 35.5263 40.3602 33.6251C41.0065 33.2263 41.6568 32.8236 42.3031 32.4248L42.3264 32.4132C41.7268 33.0869 41.1428 33.7684 40.5393 34.4344Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_206_255">
                    <rect width="49" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}