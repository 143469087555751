import React from "react";
import SubHeaderText from "../../Assets/SubHeaderText";
import { Radio, Form, Button } from "semantic-ui-react";
import DeveloperSVG from "../../Assets/DeveloperSVG";
import PublisherSVG from "../../Assets/PublisherSVG";
import NavbarLogo from "../Navbar/NavbarLogo";
import { Link } from "react-router-dom";

const SignUpPage = ({ handleRadioChange, selectedOption }) => {

    return (
        <>
            <NavbarLogo />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
                <div style={{ marginBottom: '20px' }}>
                    <SubHeaderText subHeaderText={"Join as a developer or publisher"} />
                </div>
                <div style={{ width: '30%' }}>
                    <Form>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ width: '48%' }}>
                                <label htmlFor="developer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', cursor: 'pointer' }}>
                                    <DeveloperSVG />
                                    <b>I’m developer</b>
                                    <Radio
                                        id="developer"
                                        name='customRadio'
                                        value='developer'
                                        checked={selectedOption === 'developer'}
                                        onChange={handleRadioChange}
                                    />
                                </label>
                            </div>
                            <div style={{ width: '48%' }}>
                                <label htmlFor="publisher" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', cursor: 'pointer' }}>
                                    <PublisherSVG />
                                    <b>I’m publisher</b>
                                    <Radio
                                        id="publisher"
                                        name='customRadio'
                                        value='publisher'
                                        checked={selectedOption === 'publisher'}
                                        onChange={handleRadioChange}
                                    />
                                </label>
                            </div>
                        </div>
                    </Form>
                    <div style={{ marginTop: '50px', textAlign: 'center' }}>
                        <Link to={`/create-account-${selectedOption}`}>
                            <Button primary style={{ backgroundColor: '#0FF851', color: 'black' }}>SIGN UP</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpPage;
