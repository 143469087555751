import React, { useEffect, useState } from "react";
import NavbarSearch from "../Navbar/NavbarSearch";
import { Checkbox, Dropdown, Form, FormField, Grid, Menu } from "semantic-ui-react";
import GamesReview from "./GamesReview";
import axios from "axios";
import { API_URL_ENV } from "../../config";
import Spinner from "../Loading/Loading";
import MenuDropdown from "../Navbar/MenuDropdown";
import LogoSVG from "../../Assets/LogoSVG,";

const API_URL = `${API_URL_ENV}/v1/allGames`;

const SearchGame = ({ selectedOption, user }) => {
    const platforms = [
        { key: 'all', text: 'ALL', value: null },
        { key: 'pc', text: 'PC', value: 0 },
        { key: 'Mobile', text: 'Mobile', value: 1 },
        { key: 'PlayStation', text: 'PlayStation', value: 2 },
        { key: 'Xbox', text: 'Xbox', value: 3 },
        { key: 'Nintendo', text: 'Nintendo', value: 4 }
    ];

    const genres = [
        { key: 'action', text: 'Action', value: 0 },
        { key: 'adventure', text: 'Adventure', value: 1 },
        { key: 'rpg', text: 'RPG', value: 2 },
        { key: 'strategy', text: 'Strategy', value: 3 },
        { key: 'simulation', text: 'Simulation', value: 4 },
        { key: 'puzzle', text: 'Puzzle', value: 5 },
        { key: 'sports', text: 'Sports', value: 6 },
        { key: 'racing', text: 'Racing', value: 7 },
        { key: 'fighting', text: 'Fighting', value: 8 },
        { key: 'horror', text: 'Horror', value: 9 },
        { key: 'sandbox', text: 'Sandbox', value: 10 },
        { key: 'platformer', text: 'Platformer', value: 11 },
        { key: 'shooter', text: 'Shooter', value: 12 },
        { key: 'moba', text: 'MOBA', value: 13 },
        { key: 'stealth', text: 'Stealth', value: 14 },
        { key: 'survival', text: 'Survival', value: 15 },
        { key: 'educational', text: 'Educational', value: 16 },
        { key: 'party', text: 'Party', value: 17 },
        { key: 'casual', text: 'Casual', value: 18 }
    ];

    const [checkedGenres, setCheckedGenres] = useState({});
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [selectedPlatform, setSelectedPlatform] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_URL);
                const fetchedData = response.data.developers;
                setData(fetchedData);
                setFilteredData(fetchedData);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const timerId = setTimeout(() => {
            fetchData();
        }, 1500);
        return () => clearTimeout(timerId);
    }, []);


    const handleItemClick = (item) => {
        setModalItem(item);
        setOpenModal(true);
    };

    const handleCheckboxChange = (event, { name, checked }) => {
        const genreValue = genres.find(genre => genre.key === name)?.value;
        if (genreValue !== undefined) {
            const updatedGenres = { ...checkedGenres, [genreValue]: checked };
            setCheckedGenres(updatedGenres);
            filterData(updatedGenres, selectedPlatform);
        }
    };


    const handlePlatformChange = (event, { value }) => {
        setSelectedPlatform(value);
        filterData(checkedGenres, value);
    };
    const filterData = (checkedGenres, platform) => {
        const filteredData = data.filter(item => {
            const platformFilter = platform === null || item.Platform === platform;
            const genreFilter = Object.values(checkedGenres).every(value => !value) || checkedGenres[item.Genre];
            return platformFilter && genreFilter;
        });
        setFilteredData(filteredData);
    };

    if (loading) {
        return <div><Spinner /></div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <>
            <Menu inverted style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', padding: '20px' }}>
                <Menu.Item>
                    <LogoSVG />
                </Menu.Item>

                <Menu.Item position='right'>
                    <Menu.Item position='right' >
                        <MenuDropdown user={user} />
                    </Menu.Item>
                </Menu.Item>
            </Menu>            <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={4} style={{ borderRight: '1px solid #ccc', paddingRight: '50px' }}>
                            <h3>Game platform</h3>
                            <FormField
                                id='form-input-control-error-email'
                                control={Dropdown}
                                placeholder='Select an option'
                                fluid
                                options={platforms}
                                onChange={handlePlatformChange}
                            />
                            <br></br>
                            <h3>Game genre</h3>
                            <Form>
                                {genres.map(genre => (
                                    <Form.Field key={genre.key}>
                                        <Checkbox
                                            label={genre.text}
                                            name={genre.key}
                                            checked={checkedGenres[genre.key]}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Form.Field>
                                ))}
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <GamesReview
                                handleItemClick={handleItemClick}
                                data={filteredData}
                                setOpenModal={setOpenModal}
                                openModal={openModal}
                                setModalItem={setModalItem}
                                modalItem={modalItem}
                                user={user.LastName}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </>
    );
};

export default SearchGame;
