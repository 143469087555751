import React from 'react';

export default function DeveloperSVG() {
    return (
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_206_276)">
                <path d="M19.8022 28.375C20.523 27.59 21.5453 27.1382 22.6154 27.1382H36.3023C35.1697 24.9313 33.6252 22.9651 31.599 21.2877C30.7459 20.5805 30.1428 20.4176 29.3081 21.347C28.6131 22.1209 27.7268 22.7689 26.8296 23.3095C21.2621 26.6717 14.5364 25.92 9.83309 21.3655C8.96525 20.5249 8.35114 20.4546 7.56052 21.2877C6.51617 22.3875 5.34679 23.3909 4.44585 24.5944C1.61066 28.3713 0.566303 32.811 0.110318 37.4136C-0.268444 41.2387 0.150768 42.0311 3.95677 42.6198C6.82507 43.0642 13.6648 43.3715 19.817 43.49L18.8094 31.3002C18.7211 30.2264 19.0852 29.16 19.8022 28.375Z" fill="black" />
                <path d="M19.6257 22.2393C25.6748 22.1949 30.5289 17.2627 30.5509 11.1493C30.5693 4.947 25.7079 0 19.5816 0C13.4442 0 8.57541 4.92479 8.59012 11.1234C8.60483 17.3108 13.5434 22.2837 19.622 22.2393H19.6257Z" fill="black" />
                <path d="M48.2866 30.2671H22.6154C22.1889 30.2671 21.8689 30.6152 21.9057 31.041L22.9354 43.5344L22.9648 43.9269L22.9942 44.2787L23.0236 44.6267L23.1303 45.9264C23.1597 46.2782 23.498 46.5633 23.8804 46.5633H47.0217C47.4078 46.5633 47.7424 46.2782 47.7718 45.9264L47.8785 44.6267L47.9079 44.2787L47.9373 43.9269L48.9964 31.041C49.0331 30.6152 48.7132 30.2671 48.2866 30.2671Z" fill="black" />
                <path d="M47.36 47.2261H23.5421C23.3435 47.2261 23.1854 47.389 23.1854 47.589V47.6371C23.1854 47.837 23.3435 48 23.5421 48H47.36C47.5586 48 47.7204 47.837 47.7204 47.6371V47.589C47.7204 47.389 47.5586 47.2261 47.36 47.2261Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_206_276">
                    <rect width="49" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
