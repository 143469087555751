import React, { useMemo } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ModalGame from '../ModalGame/ModalGame';

const GamesReview = React.memo(({ handleItemClick, data, setOpenModal, openModal, modalItem, user }) => {
    const getPlatformName = (platformId) => {
        switch (platformId) {
            case 0: return 'PC';
            case 1: return 'Mobile';
            case 2: return 'PlayStation';
            case 3: return 'Xbox';
            case 4: return 'Nintendo';
            default: return 'Unknown';
        }
    };

    const getGenreName = (genreId) => {
        switch (genreId) {
            case 0: return 'Action';
            case 1: return 'Adventure';
            case 2: return 'RPG';
            case 3: return 'Strategy';
            case 4: return 'Simulation';
            case 5: return 'Puzzle';
            case 6: return 'Sports';
            case 7: return 'Racing';
            case 8: return 'Fighting';
            case 9: return 'Horror';
            case 10: return 'Sandbox';
            case 11: return 'Platformer';
            case 12: return 'Shooter';
            case 13: return 'MOBA';
            case 14: return 'Stealth';
            case 15: return 'Survival';
            case 16: return 'Educational';
            case 17: return 'Party';
            case 18: return 'Casual';
            default: return 'Unknown';
        }
    };
    

    

    const renderedItems = useMemo(() => {
        return data.map((item) => (
            <CSSTransition
                key={item.id}
                timeout={300}
                classNames="game-item"
            >
                <Grid columns={2} onClick={() => handleItemClick(item)}>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div style={{ height: '127px', width: '127px', borderRadius: '16px' }}>
                                <Image src={`../../games/${item.Id}/icon.png`} alt={item.header} />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h2>{item.Name}</h2>
                            <p>{item.Description}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                        <span style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', backgroundColor: '#E6E6E6', margin: '0 10px 0 0' }}>{getPlatformName(item.Platform)}</span>
                        <span style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', backgroundColor: '#E6E6E6', margin: '0 10px 0 0' }}>{getGenreName(item.Genre)}</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </CSSTransition>
        ));
    }, [data, handleItemClick]);

    return (
        <TransitionGroup component={null}>
            {renderedItems}
            <ModalGame openModal={openModal} setOpenModal={setOpenModal} modalItem={modalItem} user={user} />
        </TransitionGroup>
    );
});

export default GamesReview;
