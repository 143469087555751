import React from "react";
import {
    ModalHeader,
    ModalContent,
    ModalActions,
    Button,
    Header,
    Image,
    Modal,
} from 'semantic-ui-react'
import Carousel from "../../Carousel/Carousel";

const ModalGame = ({ openModal, setOpenModal, modalItem, user }) => {

    const getPlatformName = (platformId) => {
        switch (platformId) {
            case 0: return 'PC';
            case 1: return 'Mobile';
            case 2: return 'PlayStation';
            case 3: return 'Xbox';
            case 4: return 'Nintendo';
            default: return 'Unknown';
        }
    };

    const getGenreName = (genreId) => {
        switch (genreId) {
            case 0: return 'Action';
            case 1: return 'Adventure';
            case 2: return 'RPG';
            case 3: return 'Strategy';
            case 4: return 'Simulation';
            case 5: return 'Puzzle';
            case 6: return 'Sports';
            case 7: return 'Racing';
            case 8: return 'Fighting';
            case 9: return 'Horror';
            case 10: return 'Sandbox';
            case 11: return 'Platformer';
            case 12: return 'Shooter';
            case 13: return 'MOBA';
            case 14: return 'Stealth';
            case 15: return 'Survival';
            case 16: return 'Educational';
            case 17: return 'Party';
            case 18: return 'Casual';
            default: return 'Unknown';
        }
    };

    if (!modalItem) {
        return null;
    }


    return (

        <Modal
            onClose={() => setOpenModal(false)}
            onOpen={() => setOpenModal(true)}
            open={openModal}
        >
            <ModalHeader>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button icon='close' onClick={() => setOpenModal(false)} />
                </div>
            </ModalHeader>
            <ModalContent >
                <div style={{ display: 'flex' }}>
                    <Image size='small' src={`../../games/${modalItem.Id}/icon.png`} wrapped />
                </div>
                <Header>{modalItem.Name}</Header>
                <p>{modalItem.Description}</p>
                <span style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', backgroundColor: '#E6E6E6', margin: '0 10px 0 0' }}>{getPlatformName(modalItem.Platform)}</span>
                <span style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', backgroundColor: '#E6E6E6', margin: '0 10px 0 0' }}>{getGenreName(modalItem.Genre)}</span>
                <Carousel modalItem={modalItem} />


            </ModalContent>
            <ModalActions>
                {user === 'publisher' &&
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => setOpenModal(false)} style={{ border: '1px solid black' }}>
                            MESSAGE
                        </Button>
                        <div>
                            <Button onClick={() => setOpenModal(false)}>
                                DOWNLOAD
                            </Button>
                            <Button style={{ backgroundColor: '#0FF851', marginLeft: '10px' }} onClick={() => setOpenModal(false)}>
                                OPEN CONTRACT
                            </Button>
                        </div>
                    </div>
                }
            </ModalActions>
        </Modal>

    )

}

export default ModalGame;
