import React from "react";


const AccountHeaderText =({accountHeaderText})=> {

    return (
        <h1 style={{fontSize:'36px', fontWeight: '700'}}>{accountHeaderText}</h1>
    )

}

export default AccountHeaderText