import React from "react";
import Slider from "react-slick";
import { Image } from 'semantic-ui-react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel({ modalItem }) {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const imageStyle = {
    width: '100%', 
    height: '431px',
    objectFit: 'contain',
    borderRadius: '10px', // Optional: adds rounded corners
  };

  return (
    <Slider {...settings}>
      <div>
        <Image size='large' src={`../../games/${modalItem.Id}/ss1.png`} style={imageStyle} />
      </div>
      <div>
        <Image size='large' src={`../../games/${modalItem.Id}/ss2.png`} style={imageStyle} />
      </div>
      <div>
        <Image size='large' src={`../../games/${modalItem.Id}/ss3.png`} style={imageStyle} />
      </div>
    </Slider>
  );
}
