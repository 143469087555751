import React from "react";


const SubHeaderText =({subHeaderText})=> {

    return (
        <h1 style={{fontSize:'48px', fontWeight: '700'}}>{subHeaderText}</h1>
    )

}

export default SubHeaderText