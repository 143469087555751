import React, { useState } from "react";
import SubHeaderText from "../../Assets/SubHeaderText";
import { FormField, Form, Input, Button, Message, MessageHeader } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";
import NavbarLogo from "../Navbar/NavbarLogo";
import axios from 'axios';
import { API_URL_ENV } from "../../config";



console.log(API_URL_ENV)


const API_URL = `${API_URL_ENV}/v1/login2`;

const LoginPage = ({ setUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(API_URL, {
                username: email,
                password: password,
            });
            const data = response.data;
            const token = data.sessionToken;


            sessionStorage.setItem('token', token);
            sessionStorage.setItem('user', JSON.stringify(data.account));
            const userStr = sessionStorage.getItem('user');
            setUser(JSON.parse(userStr))

            navigate(`/landing-page-${data.account.LastName}`);
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please try again.');
        }
    };

    return (
        <>
            <NavbarLogo />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
                <div style={{ marginBottom: '20px' }}>
                    <SubHeaderText subHeaderText={"Log in to Publish Your Game"} />
                </div>
                <div style={{ width: '20%' }}>
                    <Form>
                        <FormField
                            id='form-input-control-error-email'
                            control={Input}
                            label='Username'
                            placeholder='Enter your username'
                            value={email}
                            onChange={handleEmailChange}
                            fluid
                        />

                        <FormField
                            id='form-input-control-error-password'
                            control={Input}
                            type='password'
                            label='Password'
                            placeholder='******'
                            value={password}
                            onChange={handlePasswordChange}
                            fluid
                        />

                        <div style={{ textAlign: 'right', marginTop: '5px' }}>
                            <span>
                                <a href="/forgot-password">Forgot Password?</a>
                            </span>
                        </div>

                        <FormField style={{ marginTop: '20px' }}>
                            <Button primary fluid style={{ backgroundColor: '#0FF851', color: 'black' }} onClick={handleSubmit} >
                                Login
                            </Button>
                        </FormField>
                        {error &&

                            <Message negative>
                                <MessageHeader>{error}</MessageHeader>
                            </Message>
                        }
                    </Form>
                </div>
            </div>
        </>
    )
}

export default LoginPage;
