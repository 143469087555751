import React, { useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import LandingSVG from "../../Assets/LandingSVG";
import HeaderText from "../../Assets/HeaderText";
import Navbar from "../Navbar/Navbar";
import Working from "../../Working/Working";

const LandingPage = () => {

    const [activeItem, setActiveItem] = useState('home'); // State to track active menu item

    const handleItemClick = (name) => {
        setActiveItem(name);
    };

    const renderComponent = () => {
        switch (activeItem) {
            case 'publishers':
            case 'games':
            case 'forum':
            case 'education':
                return <Working />;
            default:
                return (
                        <>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Container style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                          

                            <Grid>
                                <Grid.Column width={7}>
                                    <HeaderText />
                                </Grid.Column>
                                <Grid.Column width={9}>
                                    
                                       <LandingSVG />
                                  
                                </Grid.Column>
                            </Grid>
                        </Container>
                        </>
                ); 
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <Navbar activeItem={activeItem} setActiveItem={setActiveItem} handleItemClick={handleItemClick} />

            {renderComponent()} {/* Render the component based on activeItem */}
        </div>
    );
}

export default LandingPage;
