import React, { useState } from "react";
import { Button, Menu } from "semantic-ui-react";
import { Link, NavLink } from 'react-router-dom';
import LogoSVG from "../../Assets/LogoSVG,";

const Navbar = ({ activeItem, setActiveItem, handleItemClick }) => {



    return (
        <>
            {/* <Menu fixed="top" inverted style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', padding: '20px' }}> */}

            <Menu inverted style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', padding: '20px' }}>
                <Menu.Item>
                    <LogoSVG />
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        <NavLink className={`nav-links ${activeItem === 'home' ? 'active-nav-link' : ''}`} onClick={() => handleItemClick('home')}>Home</NavLink>
                        <NavLink className={`nav-links ${activeItem === 'publishers' ? 'active-nav-link' : ''}`} onClick={() => handleItemClick('publishers')} >Find Publishers</NavLink>
                        <NavLink className={`nav-links ${activeItem === 'games' ? 'active-nav-link' : ''}`} onClick={() => handleItemClick('games')} >Find Games</NavLink>
                        <NavLink className={`nav-links ${activeItem === 'forum' ? 'active-nav-link' : ''}`} onClick={() => handleItemClick('forum')} >Forum</NavLink>
                        <NavLink className={`nav-links ${activeItem === 'education' ? 'active-nav-link' : ''}`} onClick={() => handleItemClick('education')} >Education</NavLink>
                    </Menu.Item>
                </Menu.Menu>
                <Menu.Item position='right'>
                    <Link to='login'>
                        <Button as='a' style={{ marginRight: '10px' }}>Log in</Button>
                    </Link>
                    <Link to='sign'>
                        <Button as='a' style={{ backgroundColor: '#0FF851' }}>Sign up</Button>
                    </Link>
                </Menu.Item>
            </Menu>





        </>
    )
}

export default Navbar;
