// Spinner.js
import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const Spinner = () => (
    <Dimmer active style={{ height: '100vh', width: '100%', position: 'fixed' }}>
        <div style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80px',
            height: '80px'
        }}>
            <Loader active inline='centered' size='large' style={{ zIndex: 1, border: 'red' }} />
            <div style={{
                position: 'absolute',
                width: '20px',
                height: '20px',
                backgroundColor: '#0FF851',
                borderRadius: '50%',
                zIndex: 2
            }} />
        </div>
    </Dimmer>
);

export default Spinner;
