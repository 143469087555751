import React, { useEffect, useState } from "react";
import NavbarSearch from "../Navbar/NavbarSearch";
import { Grid, Icon, Menu } from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css';
import { Link, NavLink } from "react-router-dom";
import axios, { all } from "axios";
import { API_URL_ENV } from "../../config";
import ModalGame from "../ModalGame/ModalGame";
import Spinner from "../Loading/Loading";
import _ from 'lodash';
import LogoSVG from "../../Assets/LogoSVG,";
import MenuDropdown from "../Navbar/MenuDropdown";

const API_URL = `${API_URL_ENV}/v1/allGames`;




const DeveloperProfile = ({ selectedOption, user }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [modalItem, setModalItem] = useState(null);

    const [images, setImages] = useState([])


    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await fetch('/games.json'); // Fetching from public directory
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                setImages(data)
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchGames();
    }, []);


    console.log(images)

    const handleItemClick = (item) => {
        setModalItem(item)
        setOpenModal(true);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(API_URL);
                const fetchedData = response.data.developers;

                setData(fetchedData)
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        const timerId = setTimeout(() => {
            fetchData();
        }, 1500);
        return () => clearTimeout(timerId);
    }, []);

    const filteredData = data.filter(item => item.DeveloperName === user.Username);

    const backgroundImageUrl = user.LastName === 'publisher'
        ? `${process.env.PUBLIC_URL}/publisherProfile.png`
        : `${process.env.PUBLIC_URL}/developerProfile.png`;

    const backgroundSize = user.LastName === 'publisher' ? 'contain' : 'cover';

    if (loading) {
        return <div><Spinner /></div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Menu inverted style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', padding: '20px' }}>
                <Menu.Item>
                    <LogoSVG />
                </Menu.Item>

                <Menu.Item position='right'>
                    <Menu.Item position='right' >
                        <MenuDropdown user={user} />
                    </Menu.Item>
                </Menu.Item>
            </Menu>


            <div style={{ paddingLeft: '100px', paddingRight: '100px' }}>
                <br />
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>

                            <div style={{
                                height: '40vh',
                                width: '100%',
                                backgroundImage: `url(${backgroundImageUrl})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: backgroundSize,

                            }}>

                            </div>

                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h1 style={{ fontSize: '40px', fontWeight: '700', margin: '0 15px 0 0' }}>   {user.LastName === 'developer' ? "DeepSea Games" : "GamePub"}</h1>
                                <span style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', backgroundColor: '#E6E6E6', margin: '0 15px 0 0' }}>{user.LastName}</span>
                                <div style={{ border: '2px solid black', padding: '5px', borderRadius: '8px' }}>
                                    <Icon name='pencil' />
                                </div>
                            </div>
                            <br></br>
                            {user.LastName === 'developer' ?
                                <p>As an accomplished game developer, I craft immersive digital worlds that captivate and inspire. With a keen eye for detail and a passion for innovation, I breathe life into pixels, creating unforgettable gaming experiences. My expertise spans across various genres, from action-packed adventures to thought-provoking narratives. Armed with a deep understanding of game mechanics and storytelling, I thrive on pushing boundaries and pushing players' imaginations to new heights. Through meticulous design and relentless dedication, I transform ideas into interactive masterpieces that leave a lasting impression. My mission is simple: to deliver unparalleled entertainment that resonates with players worldwide.</p>
                                :
                                <p>Welcome to GamePub, your premier destination for innovative and immersive gaming experiences. At GamePub, we are passionate about creating high-quality games that captivate and entertain players of all ages. Our dedicated team of developers, designers, and storytellers work tirelessly to bring you engaging gameplay, stunning visuals, and unforgettable adventures. Join us in our journey to revolutionize the gaming world, one epic game at a time.</p>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />
                <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: '40px', fontWeight: '400', margin: '0 15px 0 0' }}>
                        {user.LastName === 'developer' ? "PROJECTS" : "PUBLISHED GAMES"}
                        </h1>
                    <Link to='/add-game' style={{ textDecoration: 'none', color: 'black', display: user.LastName === 'publisher' ? 'none' : 'block' }}>
                        <div style={{ border: '2px solid black', padding: '5px', borderRadius: '8px' }}>
                            <Icon name='add' />
                        </div>
                    </Link>
                </div>
                <br />
                <Grid columns={8} >
                    {filteredData.map((item, index) => (
                        <Grid.Column key={index} onClick={() => handleItemClick(item)}>
                            
                            <div style={{ width: '394px', height: '394px' }}>
                                <img src={`../../games/${item.Id}/icon.png`} alt={item.alt} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }} />
                            </div>
                        </Grid.Column>
                    ))}
                </Grid>



            </div>

            <ModalGame openModal={openModal} setOpenModal={setOpenModal} modalItem={modalItem} user={user} />


        </>
    );
}

export default DeveloperProfile;


