import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import LoginPage from './Components/LoginPage/LoginPage';
import AccountCreate from './Components/AccountCreate/AccountCreate';
import SignUpPage from './Components/SignUpPage/SignUpPage';
import LandingPage from './Components/LandingPage/LandingPage';
import { useEffect, useState } from 'react';
import LandingPageDeveloper from './Components/LandingPage/LandingPageDeveloper';
import DeveloperProfile from './Components/DeveloperProfile/DeveloperProfile';
import AddGame from './Components/AddGame/AddGame';
import SearchGame from './Components/SearchGame/SearchGame';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';

function App() {
  const [selectedOption, setSelectedOption] = useState('developer');
  const [user, setUser] = useState([]);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleRadioChange = (e, { value }) => {
    setSelectedOption(value);
  };


  const landingPagePath = selectedOption === 'developer' ? '/landing-page-developer' : '/landing-page-publisher';

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoute />}>
          <Route index element={<LandingPage />} />
          <Route path="/login" element={<LoginPage setUser={setUser} />} />
          <Route path="/sign" element={<SignUpPage handleRadioChange={handleRadioChange} selectedOption={selectedOption} />} />
          <Route path="/create-account-developer" element={<AccountCreate accountHeaderText={"Sign up to publish your games"} selectedOption={selectedOption} />} />
          <Route path="/create-account-publisher" element={<AccountCreate accountHeaderText={"Sign up to find the best games"} selectedOption={selectedOption} />} />
        </Route>

        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="*" element={<Navigate to={landingPagePath} replace />} />
          <Route path="/landing-page-developer" element={<LandingPageDeveloper selectedOption={selectedOption} user={user} />} />
          <Route path="/landing-page-publisher" element={<LandingPageDeveloper selectedOption={selectedOption} user={user} />} />
          <Route path="/developer-profile" element={<DeveloperProfile selectedOption={selectedOption} user={user} />} />
          <Route path="/add-game" element={<AddGame user={user} />} />
          <Route path="/search-game" element={<SearchGame selectedOption={selectedOption} user={user} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
