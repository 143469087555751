import React from 'react';

export default function LandingSVG() {
    return (
        // <svg width="795" height="657" viewBox="0 0 795 657" fill="none" xmlns="http://www.w3.org/2000/svg">
        // <path d="M419.224 340.107C419.224 354.934 416.525 369.123 411.542 383.551C411.403 384.029 411.196 384.508 410.988 385.066C406.005 398.776 398.877 412.487 390.226 420.538C381.299 412.248 374.032 397.979 368.98 383.87C368.911 383.79 368.911 383.711 368.841 383.551C363.858 369.123 361.159 354.934 361.159 340.107C361.159 335.564 361.436 331.1 361.921 326.476C363.582 311.012 372.717 292.359 384.136 290.366C384.274 290.366 384.413 290.287 384.551 290.287C388.15 289.808 391.818 289.808 395.417 290.207C395.555 290.207 395.763 290.207 395.901 290.287C407.528 291.961 416.871 310.853 418.532 326.476C418.947 331.1 419.224 335.564 419.224 340.107Z" fill="#FF6BD5"/>
        // <path d="M419.224 340.107C419.224 354.934 416.525 369.123 411.542 383.551C411.403 384.029 411.196 384.508 410.988 385.066C406.005 398.776 398.877 412.487 390.226 420.538C381.299 412.248 374.032 397.979 368.98 383.87C368.911 383.79 368.911 383.711 368.841 383.551C363.858 369.123 361.159 354.934 361.159 340.107C361.159 335.564 361.436 331.1 361.921 326.476C363.582 311.012 372.717 292.359 384.136 290.366C384.274 290.366 384.413 290.287 384.551 290.287C388.15 289.808 391.818 289.808 395.417 290.207C395.555 290.207 395.763 290.207 395.901 290.287C407.528 291.961 416.871 310.853 418.532 326.476C418.947 331.1 419.224 335.564 419.224 340.107Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M739.927 422.211C729.408 425.798 720.826 434.646 716.535 446.125C708.23 442.697 699.234 440.784 689.891 440.784C662.208 440.784 637.847 457.205 623.729 481.996C616.393 478.01 608.227 475.858 599.576 475.858C595.77 475.858 592.102 476.257 588.572 477.134C588.572 476.735 588.572 476.337 588.572 475.858C588.572 455.531 576.115 438.552 559.575 434.487C553.208 409.377 533.138 390.964 509.262 390.964C489.399 390.964 472.098 403.718 463.239 422.45C457.08 415.834 448.844 411.689 439.778 411.689C434.588 411.689 429.674 413.044 425.245 415.356C419.847 405.711 415.071 395.587 410.988 384.905C400.884 358.6 395.278 329.266 395.278 298.496C395.278 295.706 395.348 292.996 395.417 290.206C396.593 254.574 405.244 221.174 419.708 191.999H359.845C374.309 221.174 382.96 254.654 384.136 290.286C384.205 292.996 384.274 295.706 384.274 298.416C384.274 328.708 378.807 357.643 368.98 383.789C362.544 400.848 354.308 416.791 344.411 431.219C336.038 419.262 323.234 411.689 308.909 411.689C294.514 411.689 281.71 419.342 273.336 431.299C266.623 426.755 258.872 424.124 250.498 424.124C237.072 424.124 224.961 430.98 216.725 441.741C204.545 420.936 183.922 407.225 160.53 407.225C123.158 407.225 92.8458 442.139 92.8458 485.185C92.8458 485.424 92.8458 485.663 92.8458 485.902C67.5162 500.729 49.7302 530.223 48 564.739H739.927V422.211Z" fill="#F0F0F0"/>
        // <path d="M222 558C222 606.049 183.049 645 135 645C86.9512 645 48 606.049 48 558C48 509.951 86.9512 471 135 471C183.049 471 222 509.951 222 558Z" fill="#F0F0F0"/>
        // <path d="M290 581.5C290 616.57 261.57 645 226.5 645C191.43 645 163 616.57 163 581.5C163 546.43 191.43 518 226.5 518C261.57 518 290 546.43 290 581.5Z" fill="#F0F0F0"/>
        // <path d="M503 565.5C503 600.57 474.57 629 439.5 629C404.43 629 376 600.57 376 565.5C376 530.43 404.43 502 439.5 502C474.57 502 503 530.43 503 565.5Z" fill="#F0F0F0"/>
        // <path d="M781 481.5C781 516.57 752.57 545 717.5 545C682.43 545 654 516.57 654 481.5C654 446.43 682.43 418 717.5 418C752.57 418 781 446.43 781 481.5Z" fill="#F0F0F0"/>
        // <path d="M439 565.5C439 600.57 410.57 629 375.5 629C340.43 629 312 600.57 312 565.5C312 530.43 340.43 502 375.5 502C410.57 502 439 530.43 439 565.5Z" fill="#F0F0F0"/>
        // <path d="M703 545.5C703 580.57 674.57 609 639.5 609C604.43 609 576 580.57 576 545.5C576 510.43 604.43 482 639.5 482C674.57 482 703 510.43 703 545.5Z" fill="#F0F0F0"/>
        // <path d="M675 510.5C675 545.57 646.57 574 611.5 574C576.43 574 548 545.57 548 510.5C548 475.43 576.43 447 611.5 447C646.57 447 675 475.43 675 510.5Z" fill="#F0F0F0"/>
        // <path d="M106 552.5C106 580.39 82.2711 603 53 603C23.7289 603 0 580.39 0 552.5C0 524.61 23.7289 502 53 502C82.2711 502 106 524.61 106 552.5Z" fill="#F0F0F0"/>
        // <path d="M406 574C406 619.84 369.735 657 325 657C280.265 657 244 619.84 244 574C244 528.16 280.265 491 325 491C369.735 491 406 528.16 406 574Z" fill="#F0F0F0"/>
        // <path d="M632 565C632 610.84 595.735 648 551 648C506.265 648 470 610.84 470 565C470 519.16 506.265 482 551 482C595.735 482 632 519.16 632 565Z" fill="#F0F0F0"/>
        // <path d="M795 535C795 580.84 758.735 618 714 618C669.265 618 633 580.84 633 535C633 489.16 669.265 452 714 452C758.735 452 795 489.16 795 535Z" fill="#F0F0F0"/>
        // <path d="M387.043 290.127C380.537 291.083 375.347 301.605 374.378 310.294C374.101 312.845 373.963 315.316 373.963 317.867C373.963 326.157 375.485 334.049 378.253 342.1C381.022 349.991 385.174 358.042 390.157 362.666C395.209 358.042 399.292 349.991 402.06 342.1C404.829 334.049 406.351 326.157 406.351 317.867C406.351 315.316 406.213 312.845 405.936 310.294C404.967 301.526 399.777 291.083 393.271 290.127C391.264 289.887 389.119 289.887 387.043 290.127Z" fill="#F007AE"/>
        // <path d="M387.043 290.127C380.537 291.083 375.347 301.605 374.378 310.294C374.101 312.845 373.963 315.316 373.963 317.867C373.963 326.157 375.485 334.049 378.253 342.1C381.022 349.991 385.174 358.042 390.157 362.666C395.209 358.042 399.292 349.991 402.06 342.1C404.829 334.049 406.351 326.157 406.351 317.867C406.351 315.316 406.213 312.845 405.936 310.294C404.967 301.526 399.777 291.083 393.271 290.127C391.264 289.887 389.119 289.887 387.043 290.127Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M306.694 168.405L284.133 178.529V129.186L353.408 66.9302V160.274L336.66 170.398C327.733 164.977 316.867 163.861 306.694 168.405Z" fill="#DADADA"/>
        // <path d="M276.797 191.442C269.737 179.645 265.654 165.695 265.654 150.789C265.654 131.897 272.09 114.599 282.818 101.287C293.545 114.599 300.05 131.897 300.119 150.789C300.119 165.775 296.036 179.645 289.046 191.442H276.797Z" fill="white"/>
        // <path opacity="0.72" d="M284.133 178.528L306.555 168.484C306.832 161.629 306.417 154.614 305.31 147.599C303.649 137.077 300.535 127.193 296.244 118.265L284.133 129.185V178.528Z" fill="#F0F0F0"/>
        // <path opacity="0.23" d="M287.039 142.977L353.478 83.3511V66.9302L284.133 129.107V178.529L287.039 177.253V142.977Z" fill="url(#paint0_linear_2009_53)"/>
        // <path opacity="0.72" d="M325.864 166.173C329.67 166.891 333.269 168.325 336.66 170.398L353.408 160.274V66.9302L324.203 93.1558C322.75 103.279 322.058 113.881 322.058 124.802C322.127 139.23 323.442 153.18 325.864 166.173Z" fill="#F0F0F0"/>
        // <path d="M306.694 168.405L284.133 178.529V129.186L353.408 66.9302V160.274L336.66 170.398C327.733 164.977 316.867 163.861 306.694 168.405Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M276.797 191.442C269.737 179.645 265.654 165.695 265.654 150.789C265.654 131.897 272.09 114.599 282.818 101.287C293.545 114.599 300.05 131.897 300.119 150.789C300.119 165.775 296.036 179.645 289.046 191.442H276.797Z" fill="white"/>
        // <path opacity="0.23" d="M281.78 101.287C282.61 102.323 283.441 103.439 284.202 104.555C277.004 117.309 272.852 132.295 272.783 148.238C272.783 164.021 274.859 178.768 281.918 191.522H275.551C268.561 179.724 264.478 165.775 264.478 150.868C264.547 131.897 271.053 114.599 281.78 101.287Z" fill="url(#paint1_linear_2009_53)"/>
        // <path opacity="0.32" d="M281.78 101.287C282.056 101.605 282.333 102.004 282.61 102.403C274.513 116.193 269.876 132.375 269.807 149.752C269.807 164.818 273.336 179.007 279.496 191.522H275.551C268.561 179.724 264.478 165.775 264.478 150.868C264.547 131.897 271.053 114.599 281.78 101.287Z" fill="url(#paint2_linear_2009_53)"/>
        // <path opacity="0.37" d="M300.119 150.789C300.119 131.897 293.614 114.678 282.818 101.287C281.987 102.323 281.157 103.439 280.396 104.555C287.593 117.309 291.745 132.295 291.815 148.238C291.815 164.021 287.801 178.768 280.742 191.522H289.046C296.036 179.645 300.119 165.695 300.119 150.789Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M300.119 150.789C300.119 131.897 293.614 114.678 282.818 101.287C282.541 101.605 282.264 102.004 281.987 102.403C290.084 116.193 294.721 132.375 294.791 149.752C294.791 164.818 291.261 179.007 285.102 191.522H289.046C296.036 179.645 300.119 165.695 300.119 150.789Z" fill="#F0F0F0"/>
        // <path opacity="0.37" d="M282.818 101.287C281.987 102.323 281.157 103.439 280.396 104.555C287.593 117.309 291.745 132.295 291.815 148.238C291.815 164.021 287.801 178.768 280.742 191.522H289.046C296.036 179.724 300.119 165.775 300.119 150.868C300.05 131.897 293.545 114.599 282.818 101.287Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M282.818 101.287C282.541 101.605 282.264 102.004 281.987 102.403C290.084 116.193 294.721 132.375 294.791 149.752C294.791 164.818 291.261 179.007 285.102 191.522H289.046C296.036 179.724 300.119 165.775 300.119 150.868C300.05 131.897 293.545 114.599 282.818 101.287Z" fill="#F0F0F0"/>
        // <path d="M290.707 115.635C288.147 110.454 285.102 105.671 281.572 101.207C278.043 105.591 274.997 110.454 272.437 115.635H290.707Z" fill="#060001"/>
        // <path d="M276.797 191.442C269.737 179.645 265.654 165.695 265.654 150.789C265.654 131.897 272.09 114.599 282.818 101.287C293.545 114.599 300.05 131.897 300.119 150.789C300.119 165.775 296.036 179.645 289.046 191.442H276.797Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M366.696 252.981C366.696 252.981 358.322 255.054 346.28 268.924C341.92 273.946 335.069 284.149 330.017 291.881C327.94 295.07 323.511 293.475 323.442 289.49L321.781 204.595C321.435 202.204 321.989 199.733 323.304 197.899L341.436 171.913L366.696 252.981Z" fill="white"/>
        // <path opacity="0.32" d="M323.304 291.164C327.248 290.526 330.224 286.62 330.293 281.997L331.055 203.08C330.709 200.689 331.262 198.218 332.577 196.385L343.927 180.043L341.366 171.913L323.304 197.819C321.989 199.733 321.435 202.124 321.781 204.515L323.304 291.164Z" fill="url(#paint3_linear_2009_53)"/>
        // <path opacity="0.72" d="M366.696 252.981L341.436 171.913L331.401 186.341C336.799 210.255 345.311 234.567 356.799 258.8C362.89 253.938 366.696 252.981 366.696 252.981Z" fill="#F0F0F0"/>
        // <path d="M366.696 252.981C366.696 252.981 358.322 255.054 346.28 268.924C341.92 273.946 335.069 284.149 330.017 291.881C327.94 295.07 323.511 293.475 323.442 289.49L321.781 204.595C321.435 202.204 321.989 199.733 323.304 197.899L341.436 171.913L366.696 252.981Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M270.222 180.92H292.991" stroke="#060001" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M276.797 191.442C269.737 179.645 265.654 165.695 265.654 150.789C265.654 131.897 272.09 114.599 282.818 101.287C293.545 114.599 300.05 131.897 300.119 150.789C300.119 165.775 296.036 179.645 289.046 191.442H276.797Z" fill="white"/>
        // <path d="M276.797 191.442C269.737 179.645 265.654 165.695 265.654 150.789C265.654 131.897 272.09 114.599 282.818 101.287C293.545 114.599 300.05 131.897 300.119 150.789C300.119 165.775 296.036 179.645 289.046 191.442H276.797Z" fill="white"/>
        // <path opacity="0.23" d="M281.78 101.287C282.61 102.323 283.441 103.439 284.202 104.555C277.004 117.309 272.852 132.295 272.783 148.238C272.783 164.021 274.859 178.768 281.918 191.522H275.551C268.561 179.724 264.478 165.775 264.478 150.868C264.547 131.897 271.053 114.599 281.78 101.287Z" fill="url(#paint4_linear_2009_53)"/>
        // <path opacity="0.32" d="M281.78 101.287C282.056 101.605 282.333 102.004 282.61 102.403C274.513 116.193 269.876 132.375 269.807 149.752C269.807 164.818 273.336 179.007 279.496 191.522H275.551C268.561 179.724 264.478 165.775 264.478 150.868C264.547 131.897 271.053 114.599 281.78 101.287Z" fill="url(#paint5_linear_2009_53)"/>
        // <path opacity="0.37" d="M300.119 150.789C300.119 131.897 293.614 114.678 282.818 101.287C281.987 102.323 281.157 103.439 280.396 104.555C287.593 117.309 291.745 132.295 291.815 148.238C291.815 164.021 287.801 178.768 280.742 191.522H289.046C296.036 179.645 300.119 165.695 300.119 150.789Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M300.119 150.789C300.119 131.897 293.614 114.678 282.818 101.287C282.541 101.605 282.264 102.004 281.987 102.403C290.084 116.193 294.721 132.375 294.791 149.752C294.791 164.818 291.261 179.007 285.102 191.522H289.046C296.036 179.645 300.119 165.695 300.119 150.789Z" fill="#F0F0F0"/>
        // <path opacity="0.37" d="M282.818 101.287C281.987 102.323 281.157 103.439 280.396 104.555C287.593 117.309 291.745 132.295 291.815 148.238C291.815 164.021 287.801 178.768 280.742 191.522H289.046C296.036 179.724 300.119 165.775 300.119 150.868C300.05 131.897 293.545 114.599 282.818 101.287Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M282.818 101.287C282.541 101.605 282.264 102.004 281.987 102.403C290.084 116.193 294.721 132.375 294.791 149.752C294.791 164.818 291.261 179.007 285.102 191.522H289.046C296.036 179.724 300.119 165.775 300.119 150.868C300.05 131.897 293.545 114.599 282.818 101.287Z" fill="#F0F0F0"/>
        // <path d="M291.953 115.635C289.392 110.454 286.347 105.671 282.818 101.207C279.288 105.591 276.243 110.454 273.682 115.635H291.953Z" fill="#060001"/>
        // <path d="M276.797 191.442C269.737 179.645 265.654 165.695 265.654 150.789C265.654 131.897 272.09 114.599 282.818 101.287C293.545 114.599 300.05 131.897 300.119 150.789C300.119 165.775 296.036 179.645 289.046 191.442H276.797Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M270.222 180.92H292.991" stroke="#060001" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M469.675 168.405L492.237 178.529V129.186L422.961 66.9302V160.274L439.709 170.398C448.637 164.977 459.502 163.861 469.675 168.405Z" fill="#DADADA"/>
        // <path opacity="0.72" d="M492.237 178.528L469.814 168.484C469.537 161.629 469.952 154.614 471.059 147.599C472.72 137.077 475.835 127.193 480.126 118.265L492.237 129.185V178.528Z" fill="#F0F0F0"/>
        // <path opacity="0.23" d="M489.399 142.977L422.961 83.3511V66.9302L492.237 129.107V178.529L489.399 177.253V142.977Z" fill="url(#paint6_linear_2009_53)"/>
        // <path opacity="0.72" d="M450.505 166.173C446.699 166.891 443.1 168.325 439.709 170.398L422.961 160.274V66.9302L452.166 93.1558C453.619 103.279 454.311 113.881 454.311 124.802C454.311 139.23 452.997 153.18 450.505 166.173Z" fill="#F0F0F0"/>
        // <path d="M469.675 168.405L492.237 178.529V129.186L422.961 66.9302V160.274L439.709 170.398C448.637 164.977 459.502 163.861 469.675 168.405Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M409.742 252.981C409.742 252.981 418.116 255.054 430.158 268.924C434.518 273.946 441.37 284.149 446.422 291.881C448.498 295.07 452.927 293.475 452.997 289.49L454.657 204.595C455.003 202.204 454.45 199.733 453.135 197.899L435.003 171.913L409.742 252.981Z" fill="white"/>
        // <path opacity="0.72" d="M453.066 291.164C449.121 290.526 446.145 286.62 446.076 281.997L445.315 203.08C445.661 200.689 445.107 198.218 443.792 196.385L432.442 180.043L435.003 171.913L453.135 197.899C454.45 199.812 455.004 202.204 454.658 204.595L453.066 291.164Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M409.742 252.981L435.003 171.913L445.038 186.341C439.64 210.255 431.127 234.567 419.639 258.8C413.549 253.938 409.742 252.981 409.742 252.981Z" fill="#F0F0F0"/>
        // <path d="M409.742 252.981C409.742 252.981 418.116 255.054 430.158 268.924C434.518 273.946 441.37 284.149 446.422 291.881C448.498 295.07 452.927 293.475 452.997 289.49L454.657 204.595C455.003 202.204 454.45 199.733 453.135 197.899L435.003 171.913L409.742 252.981Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M409.396 298.577C410.158 289.091 409.05 280.163 395.209 256.648C392.925 252.901 387.458 252.901 385.174 256.648C371.333 280.243 370.226 289.171 370.987 298.577C371.125 299.772 372.994 301.128 374.17 300.649C382.129 295.946 398.254 295.946 406.213 300.649C407.389 301.128 409.258 299.852 409.396 298.577Z" fill="#ADADAD"/>
        // <g opacity="0.32">
        // <g opacity="0.32">
        // <g opacity="0.32">
        // <path opacity="0.32" d="M377.977 298.576C377.215 289.09 378.323 280.162 392.164 256.647C392.579 255.929 393.133 255.371 393.687 254.973C391.057 252.98 387.043 253.538 385.174 256.647C371.333 280.242 370.226 289.17 370.987 298.576C371.125 299.771 372.994 301.127 374.17 300.648C375.347 300.011 376.662 299.373 378.046 298.895C378.046 298.815 377.977 298.735 377.977 298.576Z" fill="url(#paint7_linear_2009_53)"/>
        // </g>
        // <g opacity="0.32">
        // <path opacity="0.32" d="M393.687 297.301C398.462 297.699 402.96 298.815 406.213 300.649C407.251 301.127 408.912 300.011 409.327 298.895C404.76 297.381 399.085 296.823 393.687 297.301Z" fill="url(#paint8_linear_2009_53)"/>
        // </g>
        // </g>
        // </g>
        // <path d="M387.596 256.648C387.389 256.329 387.181 256.09 386.974 255.85C389.534 252.901 394.31 253.22 396.386 256.648C410.227 280.243 411.334 289.17 410.573 298.576C410.435 299.772 408.566 301.128 407.39 300.649C405.798 299.693 403.86 298.975 401.784 298.417C402.545 289.011 401.369 280.083 387.596 256.648Z" fill="#D2D2D2"/>
        // <path d="M409.396 298.577C410.158 289.091 409.05 280.163 395.209 256.648C392.925 252.901 387.458 252.901 385.174 256.648C371.333 280.243 370.226 289.171 370.987 298.577C371.125 299.772 372.994 301.128 374.17 300.649C382.129 295.946 398.254 295.946 406.213 300.649C407.389 301.128 409.258 299.852 409.396 298.577Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M405.798 269.96C438.394 208.501 450.228 148.158 442.892 97.8588C436.525 54.1759 416.04 22.6892 390.157 3C364.274 22.6892 343.789 54.1759 337.422 97.8588C330.086 148.158 341.989 208.501 374.516 269.96H405.798Z" fill="#F0F0F0"/>
        // <path opacity="0.23" d="M362.198 98.7348C366.35 59.197 377.146 27.79 391.68 4.27456C391.195 3.87599 390.641 3.47742 390.157 3.07886C364.274 22.768 343.789 54.2548 337.422 97.9376C330.086 148.237 341.989 208.58 374.516 270.039H381.091C363.028 208.58 356.938 149.512 362.198 98.7348Z" fill="url(#paint9_linear_2009_53)"/>
        // <path opacity="0.37" d="M442.892 97.8581C436.595 54.494 416.386 23.1667 390.711 3.47754C395.486 12.3257 401.576 25.0799 406.697 41.2616C416.04 70.6758 417.078 94.9087 417.632 110.134C420.123 178.608 397.355 254.335 392.579 270.039H405.798C438.394 208.5 450.228 148.157 442.892 97.8581Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M442.892 97.8588C436.525 54.1759 416.04 22.6892 390.157 3C389.465 3.47828 388.842 4.03629 388.15 4.51457C397.977 13.3627 405.452 22.8486 411.126 31.9359C462.062 113.562 413.203 235.763 406.49 251.945C406.213 240.944 404.967 227.313 401.438 212.088C399.154 202.363 396.385 193.834 393.617 186.58C392.579 213.602 391.541 240.625 390.503 267.648H399.085C398.877 268.445 398.669 269.163 398.392 269.96H405.659C438.394 208.501 450.228 148.158 442.892 97.8588Z" fill="#F0F0F0"/>
        // <path opacity="0.32" d="M346.142 111.729C347.11 98.9748 350.709 57.5239 372.924 23.4863C376.454 18.1455 381.714 10.8916 381.298 10.4931C380.676 9.85535 368.495 22.5298 359.844 36.0013C348.979 52.8208 341.02 73.4665 337.491 97.8588C330.155 148.158 342.058 208.501 374.585 269.96H377.423C368.495 249.473 362.613 230.98 358.599 216.392C355.969 206.588 351.332 189.21 348.425 165.934C346.557 150.31 344.481 133.57 346.142 111.729Z" fill="url(#paint10_linear_2009_53)"/>
        // <path opacity="0.72" d="M393.894 128.149C404.29 128.149 412.718 118.443 412.718 106.467C412.718 94.4926 404.29 84.7852 393.894 84.7852C383.498 84.7852 375.07 94.4926 375.07 106.467C375.07 118.443 383.498 128.149 393.894 128.149Z" fill="#F0F0F0" stroke="#F0F0F0" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M405.798 269.96C438.394 208.501 450.228 148.158 442.892 97.8588C436.525 54.1759 416.04 22.6892 390.157 3C364.274 22.6892 343.789 54.1759 337.422 97.8588C330.086 148.158 341.989 208.501 374.516 269.96H405.798Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M408.748 106.594C410.413 94.7741 403.445 83.6366 393.182 81.7187C382.92 79.8 373.251 87.8271 371.585 99.647C369.92 111.468 376.889 122.604 387.151 124.523C397.413 126.442 407.082 118.415 408.748 106.594Z" fill="#DCDCDC"/>
        // <path opacity="0.93" d="M387.596 114.998C393.864 114.998 398.946 109.144 398.946 101.925C398.946 94.7049 393.864 88.8516 387.596 88.8516C381.328 88.8516 376.247 94.7049 376.247 101.925C376.247 109.144 381.328 114.998 387.596 114.998Z" fill="#F0F0F0"/>
        // <path d="M408.748 106.594C410.413 94.7741 403.445 83.6366 393.182 81.7187C382.92 79.8 373.251 87.8271 371.585 99.647C369.92 111.468 376.889 122.604 387.151 124.523C397.413 126.442 407.082 118.415 408.748 106.594Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M390.157 307.266C390.918 307.266 391.541 306.309 391.61 305.034L396.801 207.465C397.354 197.421 394.586 188.094 390.503 185.862C390.226 185.703 389.949 185.703 389.742 185.862C385.659 188.015 382.89 197.341 383.444 207.465L388.634 305.034C388.773 306.309 389.465 307.266 390.157 307.266Z" fill="#979797"/>
        // <path opacity="0.74" d="M390.157 307.266C390.918 307.266 391.541 306.309 391.61 305.034L396.801 207.465C397.354 197.421 394.586 188.094 390.503 185.862C390.226 185.703 389.949 185.703 389.742 185.862C385.659 188.015 382.89 197.341 383.444 207.465L388.634 305.034C388.773 306.309 389.465 307.266 390.157 307.266Z" fill="#F0F0F0"/>
        // <g opacity="0.53">
        // <g opacity="0.53">
        // <g opacity="0.53">
        // <path opacity="0.53" d="M389.811 185.862C385.728 188.014 382.96 197.341 383.513 207.464L388.704 305.033C388.773 306.07 389.188 306.947 389.742 307.186V185.862H389.811Z" fill="url(#paint11_linear_2009_53)"/>
        // </g>
        // <g opacity="0.53">
        // <path opacity="0.53" d="M389.811 185.862H390.572C390.295 185.703 390.019 185.703 389.811 185.862Z" fill="url(#paint12_linear_2009_53)"/>
        // </g>
        // </g>
        // </g>
        // <path d="M390.157 307.266C390.918 307.266 391.541 306.309 391.61 305.034L396.801 207.465C397.354 197.421 394.586 188.094 390.503 185.862C390.226 185.703 389.949 185.703 389.742 185.862C385.659 188.015 382.89 197.341 383.444 207.465L388.634 305.034C388.773 306.309 389.465 307.266 390.157 307.266Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M491.752 191.442C484.693 179.645 480.61 165.695 480.61 150.789C480.61 131.897 487.046 114.599 497.773 101.287C508.5 114.599 515.006 131.897 515.075 150.789C515.075 165.775 510.992 179.645 504.002 191.442H491.752Z" fill="white"/>
        // <path d="M491.752 191.442C484.693 179.645 480.61 165.695 480.61 150.789C480.61 131.897 487.046 114.599 497.773 101.287C508.5 114.599 515.006 131.897 515.075 150.789C515.075 165.775 510.992 179.645 504.002 191.442H491.752Z" fill="white"/>
        // <path opacity="0.23" d="M496.804 101.287C497.635 102.323 498.465 103.439 499.227 104.555C492.029 117.309 487.877 132.295 487.807 148.238C487.807 164.021 489.884 178.768 496.943 191.522H490.576C483.586 179.724 479.503 165.775 479.503 150.868C479.503 131.897 486.008 114.599 496.804 101.287Z" fill="url(#paint13_linear_2009_53)"/>
        // <path opacity="0.32" d="M496.804 101.287C497.081 101.605 497.358 102.004 497.635 102.403C489.538 116.193 484.901 132.375 484.832 149.752C484.832 164.818 488.361 179.007 494.521 191.522H490.576C483.586 179.724 479.503 165.775 479.503 150.868C479.503 131.897 486.008 114.599 496.804 101.287Z" fill="url(#paint14_linear_2009_53)"/>
        // <path opacity="0.37" d="M515.075 150.789C515.075 131.897 508.57 114.678 497.773 101.287C496.943 102.323 496.112 103.439 495.351 104.555C502.549 117.309 506.701 132.295 506.77 148.238C506.77 164.021 502.756 178.768 495.697 191.522H504.002C510.992 179.645 515.075 165.695 515.075 150.789Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M515.075 150.789C515.075 131.897 508.57 114.678 497.773 101.287C497.496 101.605 497.22 102.004 496.943 102.403C505.04 116.193 509.677 132.375 509.746 149.752C509.746 164.818 506.217 179.007 500.057 191.522H504.002C510.992 179.645 515.075 165.695 515.075 150.789Z" fill="#F0F0F0"/>
        // <path opacity="0.37" d="M497.773 101.287C496.943 102.323 496.112 103.439 495.351 104.555C502.549 117.309 506.701 132.295 506.77 148.238C506.77 164.021 502.756 178.768 495.697 191.522H504.002C510.992 179.724 515.075 165.775 515.075 150.868C515.075 131.897 508.57 114.599 497.773 101.287Z" fill="#F0F0F0"/>
        // <path opacity="0.72" d="M497.773 101.287C497.496 101.605 497.22 102.004 496.943 102.403C505.04 116.193 509.677 132.375 509.746 149.752C509.746 164.818 506.217 179.007 500.057 191.522H504.002C510.992 179.724 515.075 165.775 515.075 150.868C515.075 131.897 508.57 114.599 497.773 101.287Z" fill="#F0F0F0"/>
        // <path d="M506.978 115.635C504.417 110.454 501.372 105.671 497.842 101.207C494.313 105.591 491.268 110.454 488.707 115.635H506.978Z" fill="#060001"/>
        // <path d="M491.752 191.442C484.693 179.645 480.61 165.695 480.61 150.789C480.61 131.897 487.046 114.599 497.773 101.287C508.5 114.599 515.006 131.897 515.075 150.789C515.075 165.775 510.992 179.645 504.002 191.442H491.752Z" stroke="#060001" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M486.492 180.92H509.261" stroke="#060001" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path d="M364 30.9191C381.44 23.9595 400.56 23.9595 418 31C410.086 19.9133 400.911 10.6069 390.965 3C381.019 10.6069 371.844 19.9133 364 30.9191Z" fill="#646464"/>
        // <path d="M364 30.9191C381.44 23.9595 400.56 23.9595 418 31C410.086 19.9133 400.911 10.6069 390.965 3C381.019 10.6069 371.844 19.9133 364 30.9191Z" stroke="black" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        // <path opacity="0.41" d="M369 28C369 28 373.776 19.4251 390 5C382.485 16.6202 381.01 23.9129 380.518 24.4739C375.742 25.5157 369 28 369 28Z" fill="white"/>
        // <defs>
        // <linearGradient id="paint0_linear_2009_53" x1="318.805" y1="66.9302" x2="318.805" y2="178.529" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint1_linear_2009_53" x1="274.34" y1="101.287" x2="274.34" y2="191.522" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint2_linear_2009_53" x1="273.544" y1="101.287" x2="273.544" y2="191.522" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint3_linear_2009_53" x1="332.804" y1="171.913" x2="332.804" y2="291.164" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint4_linear_2009_53" x1="274.34" y1="101.287" x2="274.34" y2="191.522" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint5_linear_2009_53" x1="273.544" y1="101.287" x2="273.544" y2="191.522" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint6_linear_2009_53" x1="457.599" y1="66.9302" x2="457.599" y2="178.529" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint7_linear_2009_53" x1="382.247" y1="253.805" x2="382.247" y2="300.747" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint8_linear_2009_53" x1="401.507" y1="297.134" x2="401.507" y2="300.762" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint9_linear_2009_53" x1="363.506" y1="3.07886" x2="363.506" y2="270.039" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint10_linear_2009_53" x1="358.362" y1="10.47" x2="358.362" y2="269.96" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint11_linear_2009_53" x1="386.627" y1="185.862" x2="386.627" y2="307.186" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint12_linear_2009_53" x1="390.192" y1="185.743" x2="390.192" y2="185.862" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint13_linear_2009_53" x1="489.365" y1="101.287" x2="489.365" y2="191.522" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // <linearGradient id="paint14_linear_2009_53" x1="488.569" y1="101.287" x2="488.569" y2="191.522" gradientUnits="userSpaceOnUse">
        // <stop stop-color="#E9FFE6"/>
        // <stop offset="1" stop-color="#9E9E9E"/>
        // </linearGradient>
        // </defs>
        // </svg>
        
<img src={`../../plane.png`} />

    );
}
