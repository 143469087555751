import React from "react";
import { Link } from "react-router-dom";

const HeaderText = () => {
    return (
        <div>
            <h1 style={{fontSize: '72px', fontWeight: '700', lineHeight: '77px'}}>3 Clicks to unleash your game to the world! </h1>
            <p style={{ fontSize: '20px', fontWeight: '400', }}>The all in one place where you can find support and publish your game on your terms ASAP</p>
           
            <Link to='/sign'style={{fontSize: '18px', fontWeight: '700', color: 'black'}}>
            <p >Get Started &gt;</p>
            </Link>
        </div>
    )
}

export default HeaderText;