import React from "react";
import { Link } from "react-router-dom";

const LogoSVG = () => {

    return (
        <Link to={'/'}>
           <svg width="143" height="26" viewBox="0 0 143 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2021_128)">
<path d="M21.3766 14.2569C15.3633 17.0826 5.16992 21.8716 1.24659 23.7065C0.604918 24.0001 -0.110082 23.468 0.0182517 22.7524C0.311585 21.0826 0.751585 18.5689 1.00825 17.1193L2.63992 7.85327L3.51992 2.86245C3.61158 2.38538 4.14325 2.1285 4.54659 2.36703C6.03159 3.19272 16.5916 9.15603 21.4499 11.8716C22.3849 12.4037 22.3483 13.7799 21.3583 14.2386L21.3766 14.2569Z" fill="#0FF751"/>
<path d="M142.23 14.2569C136.217 17.0826 126.023 21.8716 122.1 23.7065C121.458 24.0001 120.743 23.468 120.872 22.7524C121.165 21.0826 121.605 18.5689 121.862 17.1193L123.493 7.85327L124.373 2.86245C124.465 2.38538 124.997 2.1285 125.4 2.36703C126.885 3.19272 137.445 9.15603 142.303 11.8716C143.238 12.4037 143.202 13.7799 142.212 14.2386L142.23 14.2569Z" fill="#0FF751"/>
<path d="M27.1701 26C26.8584 26 26.6018 25.8899 26.4184 25.688C26.2534 25.4862 26.1801 25.211 26.2351 24.899L30.5251 1.22932C30.6534 0.550421 31.2951 0.0183105 31.9734 0.0183105H50.9118C52.7084 0.0183105 54.2668 0.69721 55.2934 1.94492C56.3201 3.17427 56.7234 4.82565 56.4118 6.58712L55.3301 12.6422C54.6701 16.3302 51.0768 19.3211 47.3368 19.3211H35.2001C34.8884 19.3211 34.5951 19.5779 34.5401 19.8899L33.6601 24.8073C33.5318 25.4862 32.8901 26.0183 32.2118 26.0183H27.2068L27.1701 26ZM37.5834 6.05501C37.2718 6.05501 36.9784 6.29354 36.9234 6.60547L35.8418 12.6238C35.8051 12.789 35.8418 12.9724 35.9701 13.1009C36.0801 13.2293 36.2268 13.3027 36.4101 13.3027H46.2184C47.1168 13.3027 47.9418 12.6055 48.1068 11.7247L48.8218 7.74308C48.8951 7.30271 48.8034 6.8807 48.5284 6.55042C48.2534 6.23849 47.8684 6.05501 47.4101 6.05501H37.6018H37.5834Z" fill="black"/>
<path d="M65.7249 25.9633C65.4133 25.9633 65.1566 25.8532 64.9733 25.6514C64.8083 25.4495 64.7349 25.1743 64.7899 24.8624L66.1649 17.2477L66.0733 16.367L59.5833 0.899083C59.5099 0.733945 59.5466 0.550459 59.6749 0.366973C59.8216 0.146789 60.0783 0 60.3166 0H66.6783C67.0816 0 67.4116 0.201835 67.5399 0.53211L70.8216 8.38532C70.9316 8.6422 71.1883 8.82569 71.4816 8.82569C71.7016 8.82569 71.8849 8.73395 72.0133 8.56881L78.2099 0.605505C78.5033 0.238532 78.9433 0 79.3833 0H85.7449C85.9283 0 86.0749 0.0733945 86.1483 0.220183C86.2399 0.40367 86.1849 0.623853 86.0383 0.825688L73.9199 16.3486L73.5716 17.1376L72.1966 24.7339C72.0683 25.4128 71.4266 25.945 70.7299 25.945H65.7249V25.9633Z" fill="black"/>
<path d="M91.7767 25.9449C89.98 25.9449 88.4217 25.266 87.395 24.0183C86.3684 22.7706 85.965 21.1192 86.295 19.3577L88.5867 6.71552C89.2467 3.0458 92.8584 0.0366211 96.5984 0.0366211H115.922C116.233 0.0366211 116.49 0.146713 116.655 0.348548C116.82 0.550383 116.893 0.825612 116.838 1.13754L116.142 4.95405C116.013 5.63295 115.372 6.16506 114.693 6.16506H97.295C96.635 6.16506 96.0484 6.66047 95.92 7.30268L93.9217 18.3302C93.8667 18.6605 93.94 18.9724 94.1417 19.2109C94.3434 19.4495 94.6367 19.5779 94.9667 19.5779H105.618C105.93 19.5779 106.223 19.3394 106.278 19.0091L106.737 16.4403C106.773 16.2568 106.737 16.0917 106.608 15.9632C106.498 15.8348 106.352 15.7614 106.168 15.7614H100.448C100.302 15.7614 100.173 15.7063 100.1 15.6146C100.027 15.5229 99.99 15.3944 100.027 15.2476L100.852 10.6605C100.907 10.3119 101.255 10.0366 101.603 10.0366H114.07C114.382 10.0366 114.638 10.1467 114.822 10.3485C114.987 10.5504 115.06 10.8256 115.005 11.1375L112.548 24.7339C112.42 25.3944 111.76 25.9449 111.1 25.9449L91.7584 25.9082L91.7767 25.9449Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_2021_128">
<rect width="143" height="26" fill="white"/>
</clipPath>
</defs>
</svg>

        </Link>
    )
}

export default LogoSVG;