import React, { useEffect, useState } from "react";
import { Button, Container, Dropdown, Form, FormField, Input, Menu, TextArea, Message, Dimmer, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import LogoSVG from "../../Assets/LogoSVG,";
import { API_URL_ENV } from "../../config";
import axios from "axios";
import Spinner from "../Loading/Loading";


const API_URL = `${API_URL_ENV}/v1/game`;


const AddGame = ({ user }) => {

    const genreOptions = [
        { key: 'action', value: 'action', text: 'Action' },
        { key: 'adventure', value: 'adventure', text: 'Adventure' },
        { key: 'rpg', value: 'rpg', text: 'RPG' },
        { key: 'strategy', value: 'strategy', text: 'Strategy' },
        { key: 'simulation', value: 'simulation', text: 'Simulation' },
        { key: 'puzzle', value: 'puzzle', text: 'Puzzle' },
        { key: 'sports', value: 'sports', text: 'Sports' },
        { key: 'racing', value: 'racing', text: 'Racing' },
        { key: 'fighting', value: 'fighting', text: 'Fighting' },
        { key: 'horror', value: 'horror', text: 'Horror' },
        { key: 'sandbox', value: 'sandbox', text: 'Sandbox' },
        { key: 'platformer', value: 'platformer', text: 'Platformer' },
        { key: 'shooter', value: 'shooter', text: 'Shooter' },
        { key: 'moba', value: 'moba', text: 'MOBA' },
        { key: 'stealth', value: 'stealth', text: 'Stealth' },
        { key: 'survival', value: 'survival', text: 'Survival' },
        { key: 'educational', value: 'educational', text: 'Educational' },
        { key: 'party', value: 'party', text: 'Party' },
        { key: 'casual', value: 'casual', text: 'Casual' },
    ];

    const platformOptions = [
        { key: 'mobile', value: 'Mobile', text: 'Mobile' },
        { key: 'pc', value: 'pc', text: 'PC' },
        { key: 'playStation', value: 'PlayStation', text: 'PlayStation' },
        { key: 'xbox', value: 'Xbox', text: 'Xbox' },
        { key: 'nintendo', value: 'Nintendo', text: 'Nintendo' }

    ];


    let token = sessionStorage.getItem('token');



    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [genre, setGenre] = useState('action');
    const [platform, setPlatform] = useState('Mobile');
    const [file, setFile] = useState(null); // State to store the selected file
    const [apk, setApk] = useState(null); // State to store the selected file

    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false); // State to track upload status



    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('');

    const showSnackbar = (message, color) => {
        setSnackbarMessage(message);
        setSnackbarColor(color);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        if (snackbarOpen) {
            const timer = setTimeout(() => {
                setSnackbarOpen(false);
            }, 2500);
            return () => clearTimeout(timer);
        }
    }, [snackbarOpen]);


    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleGenreChange = (e, { value }) => {
        setGenre(value);
    };

    const handlePlatformChange = (e, { value }) => {
        setPlatform(value);

    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setUploading(true); 
        setTimeout(() => {
            setUploading(false);
        }, 2000);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        setFile(selectedFile);
        setUploading(true); 
        setTimeout(() => {
            setUploading(false);
        }, 2000);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };


    const handleApkChange = (e) => {
        const selectedFile = e.target.files[0];
        setApk(selectedFile);
        setUploading(true); 
        setTimeout(() => {
            setUploading(false);
        }, 2000);
    };

    const handleApkDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        setApk(selectedFile);
        setUploading(true); 
        setTimeout(() => {
            setUploading(false);
        }, 2000);
    };

    const handleApkDragOver = (e) => {
        e.preventDefault();
    };


    const handleSubmit = async () => {
        setLoading(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 1500)); // Delay for 1.5 seconds

            await axios.post(
                API_URL,
                {
                    developerName: user.FirstName,
                    name: name,
                    description: description,
                    genre: genre,
                    platform: platform
                },
                {
                    headers: {
                        'sessionToken': token,
                        'Content-Type': 'application/json',
                    }
                }
            );
            showSnackbar('Game uploaded successfully!', 'green');
            setName('');
            setDescription('');

        } catch (error) {
            console.error('Request failed:', error);
            showSnackbar('Failed to upload game. Please try again.', 'red');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner />
    }


    return (
        <>
            <Menu inverted style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', padding: '20px' }}>
                <Menu.Item as="a" header>
                    <LogoSVG />
                </Menu.Item>
            </Menu>
            <div>
                <Container>
                    <h1 style={{ fontSize: '48px', fontWeight: '700' }}>Add a new game</h1>
                    <br></br>
                    <label style={{ fontSize: '24px', fontWeight: '700' }}>Name *</label>
                    <FormField
                        id='form-input-control-name'
                        control={Input}
                        placeholder='Enter a name'
                        fluid
                        value={name}
                        onChange={handleNameChange}
                    />
                    <br></br>
                    <Form>
                        <label style={{ fontSize: '24px', fontWeight: '700' }}>Description *</label>
                        <Form.Field>
                            <TextArea
                                placeholder='Enter a despription'
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </Form.Field>
                    </Form>
                    <br></br>
                    <label style={{ fontSize: '24px', fontWeight: '700' }}>Game Files *</label>
                    <br></br>
                    <span>You must add at least one screenshot and video</span>
                    <br></br>
                    <br></br>
                    <Form.Field onDrop={handleDrop} onDragOver={handleDragOver}>
                        <label htmlFor="fileInput" style={{ border: '2px dotted black', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer', display: 'inline-block', width: '50%' }}>
                            {file ? file.name : 'Drag and drop or browse files'}
                        </label>
                        <input id="fileInput" type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                    </Form.Field>
                    <Dimmer active={uploading} inverted>
                        <Loader inverted>Uploading...</Loader>
                    </Dimmer>
                    <br></br>
                    <span>Screenshots (.jpg, .gif, .png, up to 10 MB, no more than 4000 px in any dimension)</span>
                    <br></br>
                    <span>Videos (.mp4, .mov, .webm, .ogm, ogv, up to 100 MB, 2 maximum, &lt; 60 seconds)</span>
                    <br></br>
                    <br></br>
                    <br></br>
                    <label style={{ fontSize: '24px', fontWeight: '700' }}>APK *</label>
                    <br></br>
                    <br></br>
                    <Form.Field onDrop={handleApkDrop} onDragOver={handleApkDragOver}>
                        <label htmlFor="apkInput" style={{ border: '2px dotted black', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer', display: 'inline-block', width: '50%' }}>
                        {apk ? apk.name : 'Drag and drop or browse files'}
                        </label>
                        <input id="apkInput" type="file" style={{ display: 'none' }} onChange={handleApkChange}/>
                    </Form.Field>
                    <br></br>
                    <br></br>
                    <br></br>
                    <FormField>
                        <label style={{ fontSize: '24px', fontWeight: '700' }}>Platform *</label>
                        <Dropdown
                            placeholder='Select a genre'
                            fluid
                            selection
                            options={platformOptions}
                            value={platform}
                            onChange={handlePlatformChange}
                        />
                    </FormField>
                    <br></br>
                    <br></br>
                    <FormField>
                        <label style={{ fontSize: '24px', fontWeight: '700' }}>Gengre *</label>
                        <Dropdown
                            placeholder='Select a genre'
                            fluid
                            selection
                            options={genreOptions}
                            value={genre}
                            onChange={handleGenreChange}
                        />
                    </FormField>
                    <br></br>
                    <br></br>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to='login'>
                            <Button as='a' style={{ marginRight: '20px' }}>CANCEL</Button>
                        </Link>

                        <Button primary style={{ backgroundColor: '#0FF851' }} onClick={handleSubmit} disabled={!name || !description}

                        >
                            UPLOAD
                        </Button>

                    </div>
                    <br></br>


                    {snackbarOpen && (
                        <Message
                            color={snackbarColor}
                            header={snackbarMessage}
                            visible={snackbarOpen}
                            onDismiss={() => setSnackbarOpen(false)}
                            style={{ position: 'fixed', bottom: '10px', width: '50%', zIndex: '9999' }}

                        />
                    )}
                </Container>
            </div>
        </>
    )
}

export default AddGame