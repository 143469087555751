import React from "react";
import { Menu } from "semantic-ui-react";
import LogoSVG from "../../Assets/LogoSVG,";

const NavbarLogo = () => {
    return (

        <Menu fixed="top" inverted style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', padding: '20px' }}>
            <Menu.Item as="a" header>
                <LogoSVG />
            </Menu.Item>
        </Menu>
    )
}

export default NavbarLogo;