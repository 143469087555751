import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

const MenuDropdown = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    console.log(user.LastName)

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        navigate('/');
    };

    const backgroundImageUrl = user.LastName === 'publisher'
        ? `${process.env.PUBLIC_URL}/publisherProfile.png`
        : `${process.env.PUBLIC_URL}/developerProfile.png`;


    return (
        <div>
            <div
                style={{
                    width: '53px',
                    height: '53px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    backgroundImage: `url(${backgroundImageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
                onClick={handleOpen}
            />
            <Dropdown
                id="basic-menu"
                open={isOpen}
                onClose={handleClose}
                trigger={<></>}
                pointing="top right"
            >
                <Dropdown.Menu>
                    <Dropdown.Header onClick={handleClose} style={{ display: 'flex' }}>
                        <div
                            style={{
                                width: '41px',
                                height: '41px',
                                borderRadius: '100px',
                                cursor: 'pointer',
                                marginRight: '20px',
                                backgroundImage: `url(${backgroundImageUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'

                            }}
                            onClick={handleOpen}
                        />
                        <div>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', textTransform: 'capitalize' }}>{user.FirstName}</span>
                            <br></br>
                            <span style={{ fontSize: '10px', fontWeight: '300', textTransform: 'lowercase' }}>{user.Email}</span>
                        </div>
                    </Dropdown.Header>
                    <hr></hr>

                    <Dropdown.Item
                        as="div"
                        onClick={() => {
                            handleClose();
                            navigate("/developer-profile");
                        }}
                        style={{
                            cursor: "pointer",
                            textDecoration: "none",
                            color: "black",
                        }}
                    >
                        Profile
                    </Dropdown.Item>

                    {user.LastName !== 'publisher' ?
                        <Dropdown.Item
                            as="div"
                            onClick={() => {
                                handleClose();
                                navigate("/add-game");
                            }}
                            style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: "black",
                            }}
                        >
                            Add Games
                        </Dropdown.Item>
                        :
                        <Dropdown.Item onClick={handleClose}>Published Games</Dropdown.Item>
                    }

                    {user.LastName === 'publisher' && (
                        <Dropdown.Item
                            as="div"
                            onClick={() => {
                                handleClose();
                                navigate("/search-game");
                            }}
                            style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: "black",
                            }}
                        >
                            Find Games
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={handleClose}>Contracts</Dropdown.Item>
                    <Dropdown.Item onClick={handleClose}>Change Password
                    </Dropdown.Item>
                    <hr></hr>
                    <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item> {/* Call handleLogout on click */}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default MenuDropdown;
