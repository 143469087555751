import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormField, Form, Input, Button, Dropdown, Message, MessageHeader } from 'semantic-ui-react';
import axios from 'axios';

const countryOptions = [
    { key: 'us', value: 'us', text: 'United States' },
    { key: 'ca', value: 'ca', text: 'Canada' },
    { key: 'uk', value: 'uk', text: 'United Kingdom' },
];

const AccountCreateForm = ({ selectedOption }) => {
    const API_URL = 'http://192.248.191.165:8081/192.248.191.165:8080/v1/register';

    const [formData, setFormData] = useState({
        email: "",
        username: "",
        firstName: "",
        lastName: selectedOption,
        password: "",
        country: "us"
    });

    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleChange = (e, { name, value }) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async () => {
        try {
            await axios.post(API_URL, formData);
            navigate('/login');
        } catch (error) {
            console.error('Registration failed:', error);
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <div style={{ width: '30%' }}>
            <Form onSubmit={handleSubmit}>
                <FormField
                    control={Input}
                    label='Name *'
                    placeholder='Enter a first name'
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                />

                <FormField
                    control={Input}
                    label='Studio name *'
                    placeholder='Enter a studio name'
                    fluid
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                />

                <FormField
                    control={Input}
                    label='Email *'
                    placeholder='Enter an e-mail'
                    fluid
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />

                {!formData.email.includes('@') && formData.email !== '' && (
                    <Message negative>
                        Please enter a valid email
                    </Message>
                )}


                <FormField
                    control={Input}
                    type='password'
                    label='Password *'
                    placeholder='******'
                    fluid
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                />

                <FormField>
                    <label>Country *</label>
                    <Dropdown
                        placeholder='Choose a Country'
                        fluid
                        selection
                        options={countryOptions}
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                    />
                </FormField>
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <Button primary style={{ backgroundColor: '#0FF851', color: 'black' }} type="submit"
                        disabled={
                            !formData.email.includes('@') ||
                            formData.firstName === '' ||
                            formData.password === '' ||
                            formData.username === ''
                        }
                    >CREATE MY ACCOUNT</Button>
                </div>

                {error &&

                    <Message negative>
                        <MessageHeader>{error}</MessageHeader>
                    </Message>
                }
            </Form>
        </div>
    );
};

export default AccountCreateForm;
