import React from "react";
import AccountHeaderText from "../../Assets/AccountHeaderText";
import AccountCreateForm from "./AccountCreateForm";
import NavbarLogo from "../Navbar/NavbarLogo";


const AccountCreate = ({ accountHeaderText, selectedOption }) => {

    return (
        <>
        <NavbarLogo />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}>
            <div style={{ marginBottom: '20px' }}>
                <AccountHeaderText accountHeaderText={accountHeaderText} />
            </div>
            <AccountCreateForm selectedOption={selectedOption}/>
        </div>
        </>
    )

}

export default AccountCreate;
