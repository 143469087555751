import { Navigate, Outlet } from 'react-router-dom';

const PublicRoute = () => {
  const token = sessionStorage.getItem('token');
  const type = sessionStorage.getItem('type');
  const landingPagePath = type === 'developer' ? '/landing-page-developer' : '/landing-page-publisher';

  return token ? <Navigate to={landingPagePath} replace /> : <Outlet />;
}

export default PublicRoute;
